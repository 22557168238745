@use '~@moved/ui/src/sondheim/common' as *;


.contactRow {
  transition: background-color 0.2s;
  border-radius: $radius-4;
  padding: $sp-4 0;
  &:hover {
    background-color: $backgroundPrimary;
  }
}

.divider {
  display: flex;
  align-items: center;
  &:before, &:after {
    content: '';
    border-top: 1px solid $borderTertiary;
    flex: 1 1 auto;
  }
  &:before { margin-right: $sp-28; }
  &:after { margin-left: $sp-28; }
}
