@use '~@moved/ui/src/sondheim/common' as *;

.vendor {
  grid-area: vendor;
}

.description {
  grid-area: description;
}

.cta {
  grid-area: cta;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: $sp-4;
  border-top: 1px solid $borderSecondary;
  padding-top: $sp-20;

  p {
    margin: unset;
  }
}

.wrapper {
  display: grid;
  gap: $sp-20;
  grid-template-columns: 1fr;
  grid-template-areas:
    'vendor'
    'description'
    'cta';
  min-height: 100px;
}

@include breakpoints(lg,xl) {
  .wrapper {
    grid-template-columns: 150px auto 210px;
    grid-template-areas:
     'vendor description cta';
  }

  .item:not(:first-child) {
    border-left: 1px solid $borderSecondary;
    padding-left: $sp-20;
  }

  .cta {
    border-top: none;
    padding-top: initial;
  }
}

.logo {
  max-height: 56px;
  max-width: 150px;
}
